import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Head from "next/head";
import Link from "next/link";
import Router from "next/router";
import Cookie from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { LocalizationProvider, Localized } from "@fluent/react";

import KButton from "@kikoff/components/src/v1/buttons/KButton";
import LoadableButton from "@kikoff/components/src/v1/buttons/LoadableButton";
import TextInput, {
  TextInputAction,
} from "@kikoff/components/src/v1/inputs/TextInput";
import useForm from "@kikoff/hooks/src/useForm";
import useObjectState from "@kikoff/hooks/src/useObjectState";
import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import yup from "@kikoff/utils/src/yup";

import { ErrorPanel } from "@component/errors";
import Form from "@component/form";
import Header from "@component/layout/header";
import Line from "@component/line";
import CountryRedirectHeader from "@component/onboarding/country_redirect_header";
import MfaRequiredBanner from "@component/onboarding/mfa/login_banner";
import { selectFeatureFlag } from "@feature/page";
import { initUser, updateUserState } from "@feature/user";
import GoogleSignInButton from "@src/components/buttons/GoogleSignInButton";
import { useOverlaysController } from "@src/overlay";
import analytics, { track } from "@util/analytics";
import { l10nResources } from "@util/l10n";

import styles from "./login.module.scss";

declare global {
  namespace google.accounts.id {
    interface GsiButtonConfiguration {
      nonce?: string;
    }
  }
}

const csrfToken: string = uuidv4();

const l10n = l10nResources({
  en: `email-placeholder = Email
password-placeholder = Password
login-welcome-title = Welcome back!
forgot-password = Forgot password
forgot-email = Forgot email
login-no-acct = Don’t have an account?
login-signup-here = Sign up here
login = Log in
email = Email
contact = Contact
back-to-login = Back to login
reset-password = Reset Password
email-address = Email Address
`,
  es: `email-placeholder = Email
password-placeholder = Clave
login-welcome-title = ¡Bienvenido de nuevo!
forgot-password = Has olvidado tu contraseña
forgot-email = Has olvidado tu correo electrónico
login-no-acct = ¿No tienes una cuenta?
login-signup-here = Registrate aquí
login = Iniciar sesión
email = Correo electrónico
contact = Contacto
back-to-login = Atrás para iniciar sesión
reset-password = Restablecer la contraseña
email-address = Dirección de correo electrónico
`,
});

type SubmitButtonStatus = "initial" | "pending" | "success";

export default function LoginPage() {
  const dispatch = useDispatch();

  const [view, setView] = useState(0);
  const overlays = useOverlaysController();

  const hiddenState = useState(true);

  const user = useSelector((state) => state.user);
  const ssoEnabled = useSelector(selectFeatureFlag("google_sso"));

  const [password, setPassword] = useObjectState({ value: "", focus: false });
  const [buttonStatus, setButtonStatus] = useState<SubmitButtonStatus>(
    "initial"
  );
  const [successText, setSuccessText] = useState("");

  const { register, handleSubmit, submittable, error } = useForm({
    schema: useMemo(
      () =>
        yup.object({
          email: yup.string().email().required(),
          password: yup.string().password().required(),
        }),
      []
    ),
    defaultRegisterOptions: {
      include: ["error", "valid"],
    },
  });

  useEffect(() => {
    const resetEmailQueryParam = new URLSearchParams(
      window.location.search
    ).get("reset_email");

    if (resetEmailQueryParam !== null) {
      setView(1);
    }

    Cookie.set("k_csrf_token", csrfToken, { domain: window.location.host });
  }, []);

  useEffect(() => {
    // XXX: removing overlays handles an edgecase when refreshing page with
    //   an overlay open, then closing via "X" to logout. An invisible
    //   overlay can remain over the login form.
    overlays.removeAll();

    if (user.mfaRequired) {
      overlays.push("identity_verification/mfa", {
        purpose: "USER_LOGIN",
      });
    }
  }, [user.mfaRequired]);

  const handleSuccess = (text) => () => {
    setSuccessText(text);
    setButtonStatus("success");
    setTimeout(() => {
      setButtonStatus("pending");

      setTimeout(() => {
        error.throw("Something went wrong, please reload the page");
      }, 5000);
    }, 1500);
  };

  // Put here intentionally to reset form whenever view changes
  function ResetPassword() {
    const [message, setMessage] = useState("");

    return (
      <Form
        expand
        dynamic
        title={l10n.getString("reset-password")}
        className={styles["forgot-password-form"]}
        fieldsMinHeight="130px"
        submit={{
          buttonContent: {
            initial: l10n.getString("reset-password"),
          },
          before(sendRequest, { formObj }) {
            track.tap("Auth - Reset Password Button", {
              contact: formObj.type,
            });
            sendRequest();
          },
          request: {
            service: webRPC.Auth,
            method: "resetPassword",
            buildProto: ({ email }) => {
              return {
                contactPoint: email,
              };
            },
            getStatusHandlers: ({ setButton }) => ({
              SUCCESS(data) {
                setButton("success");
                setMessage(
                  {
                    EMAIL:
                      "If the email is valid, you'll receive a password reset link.",
                  }[
                    web.public_.ResetPasswordResponse.ContactPointType[
                      data.contactPointType
                    ]
                  ]
                );
              },
            }),
          },
        }}
      >
        <div className={styles.container}>
          <TextInput
            variant="outlined"
            label={l10n.getString("email-address")}
            className={styles.contact}
            key="email"
            name="email"
            autoFocus
            autoComplete="email"
            autoCapitalize="none"
          />
        </div>
        <span className={styles.back} onClick={() => setView(0)}>
          <span className={styles.icon}></span>{" "}
          {l10n.getString("back-to-login")}
        </span>
        <div className={styles.message}>
          <ErrorPanel
            // @ts-expect-error
            message={message}
            show={!!message}
            type="Success"
            color="#00f300"
            onClose={() => setMessage("")}
          />
        </div>
        <KButton
          onClick={() => Router.push("/forgot-email/form")}
          variant="text-underline"
          size="full-width"
          type="button"
        >
          Forgot Email
        </KButton>
      </Form>
    );
  }

  return (
    <div className={styles["login-page"]}>
      <LocalizationProvider l10n={l10n}>
        <Head>
          <title>Login | Kikoff</title>
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://kikoff.com/login" />
        </Head>
        <Header className={styles.header} fixed />
        <CountryRedirectHeader />
        <MfaRequiredBanner />
        {view === 0 && (
          <div className={styles["form-container"]}>
            <form
              onSubmit={handleSubmit((_, data) => {
                setButtonStatus("pending");

                webRPC.Auth.login({
                  email: data.email,
                  password: data.password,
                })
                  .then(
                    handleProtoStatus({
                      SUCCESS({ mfaRequired }) {
                        dispatch(updateUserState({ mfaRequired }));
                        handleSuccess("Logged In");

                        analytics.only("Snapchat").convert("Login");

                        setTimeout(() => {
                          dispatch(initUser());
                        }, 1500);
                      },
                      _DEFAULT: handleFailedStatus(),
                    })
                  )
                  .catch((err) => {
                    setButtonStatus("initial");
                    error.throw(err.message);
                  });
              })}
            >
              <div className="text:heading-3">
                {l10n.getString("login-welcome-title")}
              </div>
              {ssoEnabled && (
                <>
                  <GoogleSignInButton
                    onStateChange={(state) => {
                      if (state === "initial" || state === "pending") {
                        return setButtonStatus(state);
                      }

                      handleSuccess(
                        {
                          authenticated: "Logged In",
                          created: "Created",
                        }[state]
                      );

                      setTimeout(() => {
                        dispatch(initUser());
                      }, 1500);
                    }}
                    csrfToken={csrfToken}
                    error={error}
                    disabled={false}
                    requestType={web.public_.CallbackRequest.RequestType.LOGIN}
                  />
                  <Line className="mt-2 mb-1" text="or" />
                </>
              )}
              <TextInput
                variant="outlined"
                label={l10n.getString("email-placeholder")}
                name="email"
                autoComplete="email"
                autoCapitalize="none"
                {...register("email")}
              />
              <TextInput
                variant="outlined"
                label={l10n.getString("password-placeholder")}
                name="password"
                autoComplete="current-password"
                type="password"
                {...TextInputAction.Visibility.propsWithState(hiddenState)}
                {...register("password", {
                  include: ["valid"],
                  onChange(e) {
                    setPassword({ value: e.currentTarget.value });
                  },
                  onFocus() {
                    setPassword({ focus: true });
                  },
                  onBlur() {
                    setPassword({ focus: false });
                  },
                })}
              />
              <ErrorPanel
                // @ts-expect-error
                message={error.message}
                className={styles.error}
                show={error.show}
                onClose={error.hide}
              />
              <div
                className={styles["forgot"]}
                data-cy="forgot-password-button"
                onClick={() => {
                  track("Auth - Forgot Password Button");
                  setView(1);
                }}
              >
                {l10n.getString("forgot-password")}{" "}
                <span className={styles.icon}></span>
              </div>
              <div
                className={styles["forgot"]}
                onClick={() => {
                  track("Auth - Forgot Email Button");
                  Router.replace("forgot-email/form");
                }}
              >
                {l10n.getString("forgot-email")}{" "}
                <span className={styles.icon}></span>
              </div>
              <KButton
                className={styles.submit}
                as={LoadableButton}
                variant="black"
                size="full-width"
                text={{
                  success: successText,
                }}
                status={buttonStatus}
                state={submittable ? "active" : "disabled"}
                type="submit"
              >
                Log In
              </KButton>
            </form>
            <div className={styles["signup-link"]}>
              <Localized id="login-no-acct">Don’t have an account?</Localized>{" "}
              <Link href="/onboarding">
                <a>
                  <Localized id="login-signup-here">Sign up here</Localized>
                </a>
              </Link>
            </div>
          </div>
        )}
        {view === 1 && <ResetPassword />}
      </LocalizationProvider>
    </div>
  );
}
